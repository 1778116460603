import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgMail32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path fillRule="evenodd" d="M30.501 6.857c-.004-.011.002-.023-.002-.035l-.006-.008a.798.798 0 0 0-.339-.384.791.791 0 0 0-.409-.127H2.254a.782.782 0 0 0-.401.122.63.63 0 0 0-.08.055.8.8 0 0 0-.264.321h-.001v.008a.803.803 0 0 0-.062.302v17.777c0 .447.361.809.809.809h27.491a.804.804 0 0 0 .808-.808V7.11a.798.798 0 0 0-.051-.255v.002ZM3.062 8.94c2.073 1.857 5.938 5.271 8.383 7.426l-8.383 6.702V8.939Zm9.613 8.511.96.846c.691.616 1.49.929 2.375.929.88 0 1.696-.282 2.308-.806l1.075-.961 8.112 6.625H4.382l8.293-6.631zm7.939-1.085 8.323-7.444v14.239zm7.018-8.446c-3.266 2.923-10.378 9.281-10.375 9.281-.607.518-1.749.6-2.548-.114A2532.912 2532.912 0 0 1 4.347 7.921h23.286Z" /></SvgIcon>;
};
const Memo = memo(SvgMail32);
export default Memo;